import React from 'react'
import styler from '../../helpers/styler'
import Api from '../../helpers/api'
import { connect } from 'react-redux'
import moment from 'moment'
import history from '../../helpers/history'
import Fade from '../../helpers/fade'
import Head from '../Layout/Head'

class ReportCondolence extends React.Component {

  state = {
    condolence: null,
    message: '',
    user_name: (this.props.user.firstname) ? (this.props.user.firstname + ' ' + this.props.user.lastname) : '',
    user_email: '',
    reporting: null,
    missing: false
  }

  componentDidMount () {
    Api.post('get-condolence', {
      condolenceId: this.props.match.params.id
    }).then((data) => {
      this.setState({
        condolence: data.data.condolence
      })
    })
    this.setUserMail();
    this.setUserName();
  }

  /**
   * input change handler
   *
   * @param event
   */
  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  report = (event) => {

    var name = document.getElementsByName("user_name")[0];
    var email = document.getElementsByName("user_email")[0];
    var message = document.getElementsByName("message")[0];

    if(name.value === '' || email.value === '' || message.value === '') {
      (name.value === '') ? name.style.borderColor = 'red' : name.style.borderColor = '#eae1c5';
      (email.value === '') ? email.style.borderColor = 'red' : email.style.borderColor = '#eae1c5';
      (message.value === '') ? message.style.borderColor = 'red' : message.style.borderColor = '#eae1c5';
    }else{
      name.style.borderColor = '#eae1c5'
      email.style.borderColor = '#eae1c5'
      message.style.borderColor = '#eae1c5'
      event.preventDefault()
      this.setState({reporting: true})
      Api.post('report-condolence', {
        condolence_id: this.props.match.params.id,
        user_name: this.state.user_name,
        user_email: this.state.user_email,
        user_id: this.props.user.id || 0,
        message: this.state.message
      }).then(() => {
        this.setState({reporting: false})
      })
    }
  }

  setUserMail() {
    if(this.props.user.email) {
      this.setState({
        user_email: this.props.user.email
      })
    }
  }

  setUserName() {
    if(this.props.user.firstname && this.props.user.lastname) {
      this.setState({
        user_name: this.props.user.firstname + ' ' + this.props.user.lastname
      })
    }
  }

  render () {
    const canonicalUrl = window.location.href;
    return (
        <>
          <Head title="Kondolenzeintrag melden" canonicalUrl={canonicalUrl}>
            <meta name="robots" content="noindex, nofollow"/>
          </Head>
          <div className={styler(['uk-container', 'uk-padding'])}>
            <div className={styler(['condolences', 'uk-margin-small-top'])}>
              <div className={styler(['uk-padding-small'])}>
                <div className={styler(['divider'])}>
                  <h2>Kondolenzeintrag&nbsp;melden</h2>
                </div>

                {(this.state.condolence) ? (
                    <Fade>
                      <div>
                        <p>für <strong>{this.state.condolence.death_notice.vname} {this.state.condolence.death_notice.nname}</strong></p>
                        <p>von <strong>{this.state.condolence.user_name}</strong> am <strong>{moment(this.state.condolence.create_time, 'YYYY-MM-DD H:i:s').format('LL')}</strong></p>

                        <div className={styler(['uk-textarea', 'uk-padding'])}>
                          <span dangerouslySetInnerHTML={{ __html: this.state.condolence.text }}/>
                        </div>

                        <hr/>

                        <p>Wenn Sie der Meinung sind, dass dieser Eintrag unpassend ist und nochmal geprüft werden soll,
                          geben Sie hier bitte den Grund ein:</p>
                        <form className="uk-form-horizontal">
                          <div className={styler(['uk-margin-bottom'])}>
                            <textarea name="message"
                                      className={styler(["uk-textarea", "uk-padding"])}
                                      onChange={this.handleInputChange}
                                      value={this.message} required
                            />
                          </div>
                          <div className={styler(['uk-margin-bottom'])}>
                            <label className={styler(['uk-form-label'])}>
                              Ihr Name: </label>
                            <div className={styler(['uk-form-controls'])}>
                              <input className={styler(['uk-input'])}
                                     name="user_name"
                                     type="text"
                                     value={this.state.user_name}
                                     onChange={this.handleInputChange} required/>
                            </div>
                          </div>
                          <div className={styler(['uk-margin-bottom'])}>
                            <label className={styler(['uk-form-label'])}>
                              Ihre E-Mail für Rückfragen: </label>
                            <div className={styler(['uk-form-controls'])}>
                              <input className={styler(['uk-input'])}
                                     name="user_email"
                                     type="email"
                                     value={this.state.user_email}
                                     onChange={this.handleInputChange} required/>
                            </div>
                          </div>

                          <input className={styler(['uk-hidden'])} name="bee" onChange={this.handleInputChange}/>

                          {this.state.missing &&
                          <div className={styler(['uk-text-right','uk-margin-small-bottom'])}>Bitte füllen Sie alle Felder aus!</div>
                          }
                          <div className={styler(['uk-pull-right'])}>
                            <button className={styler(['uk-button', 'uk-button-primary'])}
                                    onClick={this.report} disabled={this.state.reporting || this.state.reporting === false}>

                              {this.state.reporting === true &&
                              <Fade>
                                <div data-uk-spinner={'ratio:0.8'}/>
                              </Fade>
                              }

                              {this.state.reporting === false &&
                              <Fade>
                                <span>Wir werden diesen Kondolenzeintrag in Kürze prüfen.</span>
                              </Fade>
                              }

                              {this.state.reporting === null &&
                              <span>Eintrag melden</span>
                              }


                            </button>

                            {this.state.reporting === false &&
                            <Fade>
                              <span className={styler(['uk-margin-left', 'link'])} onClick={() => history.goBack()}>zurück</span>
                            </Fade>
                            }
                          </div>



                        </form>


                      </div>
                    </Fade>
                ) : (
                    <div className={styler(['loading'])}>
                      <div data-uk-spinner={''}/>
                      <div>Lade Daten...</div>
                    </div>
                )}
              </div>
            </div>
          </div>
        </>
    )
  }
}

function mapStateToProps (state) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(ReportCondolence)
